@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "primeicons/primeicons.css";

@font-face {
  font-family: Marianne;
  font-weight: 200;
  src: url(../assets/fonts/marianne/Marianne-Thin.otf) format("opentype");
}

@font-face {
  font-family: Marianne;
  font-weight: 300;
  src: url(../assets/fonts/marianne/Marianne-Light.otf) format("opentype");
}

@font-face {
  font-family: Marianne;
  font-weight: 400;
  src: url(../assets/fonts/marianne/Marianne-Regular.otf) format("opentype");
}

@font-face {
  font-family: Marianne;
  font-weight: 500;
  src: url(../assets/fonts/marianne/Marianne-Medium.otf) format("opentype");
}

@font-face {
  font-family: Marianne;
  font-weight: 600;
  src: url(../assets/fonts/marianne/Marianne-Bold.otf) format("opentype");
}

@font-face {
  font-family: Marianne;
  font-weight: 700;
  src: url(../assets/fonts/marianne/Marianne-ExtraBold.otf) format("opentype");
}

@import "./variables.scss";

html {
  height: 100%;
}

.sr-only {
  position: absolute;
  width: 0;
  overflow: hidden;
}

body {
  font-family: Marianne, sans-serif;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--gray-100);
  color: #000;
}

main {
  white-space: pre-line;
}

.p-component {
  font-family: Marianne, sans-serif;
}

.actions {
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: 1rem;
  }
}

.archimed-label {
  margin-right: 20px;
  align-self: center;
  width: 30%;
  text-align: end;
}

.delete-action {
  padding-top: 1rem;
}

.detail-form {
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1em 2em;
  align-items: center;

  label {
    white-space: nowrap;
  }
}

.btns-box {
  grid-column: 2 / span 1;
  justify-self: end;
}

.btns-box button {
  margin-left: 1em;
}

.header-actions {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  flex-direction: column;
}

.primary-action {
  background-color: $primary-color;
  color: $primary-color;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2.5rem;
  width: 3rem;

  fa-icon {
    color: $white;
  }
}

.header-actions-button {
  background-color: $primary-color;
  color: $white;
  font-weight: 600;
  font-size: 1rem;
}

.file-input-action-button,
.historic-popin-delete-button {
  background-color: unset;
  color: $white;

  fa-icon {
    color: $primary-color;
  }
}

.file-input-action-button {
  font-size: 1rem;
  font-weight: 500;
  width: 3rem;
  cursor: pointer;
  border: none;
}

.historic-popin-delete-button {
  font-size: 1.25rem;
  font-weight: 500;
  width: 3rem;
}

.radio-button-container {
  display: flex;
  flex-direction: row;

  p-radiobutton {
    margin: 0 0.5rem;
  }
}

.form-input-container,
.form-error-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-input-container {
  margin: 0.25rem 0 0.25rem 0;

  > label {
    margin-right: 20px;
    align-self: center;
    width: 30%;
    text-align: end;
  }

  > legend {
    margin-right: 20px;
  }

  p-dropdown,
  input,
  p-calendar,
  sb-file-field,
  textarea,
  > p-checkbox,
  p-autoComplete,
  sb-file-upload,
  sb-multi-file-upload,
  sb-multi-file-commented-upload,
  p-inputnumber,
  p-inputSwitch {
    width: 50%;
  }

  p-dropdown {
    ::ng-deep .p-dropdown {
      width: 100%;
    }

    ::ng-deep .p-dropdown-label {
      font-family: Marianne, sans-serif;
    }
  }

  input:read-only {
    outline: none;
    border: none;
  }

  textarea:read-only {
    outline: none;
    border: none;
    resize: none;
  }

  p-dropdown:read-only {
    outline: none;
    border: none;

    ::ng-deep .p-dropdown,
    ::ng-deep .p-dropdown-label {
      border: none;
      cursor: text;
    }

    ::ng-deep .p-dropdown-trigger {
      display: none;
    }
  }

  ::ng-deep {
    .p-inputtext {
      padding: 0.5rem 1rem;
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    width: 50%;

    .checkbox-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      span {
        width: 95%;
        text-align: left;
      }
    }

    input[type="checkbox"] {
      width: 5%;
      margin-bottom: 0.25rem;
      accent-color: $primary-color;
      height: 1rem;
    }

    input:disabled {
      background: $primary-color;
    }

    input[disabled]::before {
      background: $primary-color;
    }

    input[disabled]::after {
      background: $primary-color;
    }
  }

  .p-autocomplete-input {
    width: 350px;
  }
}

.form-readonly-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  label {
    align-self: center;
    width: 50%;
    text-align: end;
  }
  .value-display {
    font-weight: 300;
    margin-left: 12px;
  }
}

.span-error {
  color: $error-color;
}

.form-error-container {
  span {
    margin-left: 36%;
    width: 50%;
  }
}

.panel-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.file-list-container {
  display: flex;
  flex-direction: column;
  width: 50%;

  sb-file-field {
    width: 100%;
  }
}

.align-self-start-label {
  align-self: flex-start !important;
}

.required-asterisk {
  color: $error-color;
  font-weight: bold;
  margin-left: 5px;
  font-size: 1em;
}

.form-action-container,
.form-action-container-center {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  button {
    margin-right: 0.5rem;
  }
}

.form-action-container {
  justify-content: flex-end;
}

.form-action-container-center {
  justify-content: center;
}

.error-container {
  display: flex;
  justify-content: space-evenly;
  div {
    width: 30%;
  }
  span {
    width: 50%;
  }
}

.alert-message-container {
  span {
    color: $error-color;
  }
}

form {
  .action-container {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 0.5rem;
    }
  }
}

.elements-list-container {
  .elements-list-line {
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    cursor: pointer;
    background-color: $grey-200;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 200px;
    margin-right: 200px;
    justify-content: flex-start;
    border: 1px solid transparent;

    .elements-list-line-header {
      color: $primary-color;
      width: 20%;
      text-align: end;
      margin-right: 1rem;
    }

    label {
      margin-right: 5px;
      font-weight: 500;
    }

    .elements-list-line-content {
      width: auto;
      margin-right: 2rem;
    }
  }
}

.display-blocs {
  padding: 1rem;
}

.template-element-container {
  margin-left: 10px;
  width: 100%;
}

.file-container {
  sb-file-upload {
    ::ng-deep .file-upload {
      display: flex;
      margin-left: 10px;
    }

    ::ng-deep .file-upload span {
      font-weight: 100;
      font-size: 0.8em;
      word-break: break-word;
      width: auto;
    }
  }
}

.display-none {
  display: none;
}

.other-document-action-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .add-other-document-button {
    width: 40%;
    display: inline-block;

    fa-icon {
      margin-right: 0.5em;
    }

    span {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1rem;
      width: 80%;
      vertical-align: middle;
    }
  }
}

.min-width-auto {
  min-width: auto;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-1-75em {
  font-size: 1.75em;
}

.fs-2em {
  font-size: 2em;
}

.filter-container {
  margin: 0.5rem 0;
  width: 60%;
  margin-left: 20%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.result-number-display {
  width: 25%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
  font-weight: bold;
}

.search-action-container {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    cursor: pointer;
    height: 2rem;
    margin: 0 0.5rem;
  }
}

.table-line {
  cursor: pointer;
}

.results-container {
  margin-bottom: 2rem;
}

h2,
.large-text {
  font-size: large;
  font-weight: bold;
}

h3 .medium-text {
  font-size: medium;
  font-weight: bold;
}

.width-50 {
  width: 50%;
}
.width-40 {
  width: 40%;
}
.fb-20 {
  flex-basis: 20%;
}
.fg-1 {
  flex-grow: 1;
}
.fs-0 {
  flex-shrink: 0;
}

p-sidebar h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  color: black;
  margin-bottom: 30px;
  margin-top: 0px;
}

caption {
  display: none;
}
