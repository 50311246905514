$primary-color: #000091;
$white: #fff;
$beige: #f9f8f6;
$light-blue: #d4dfee;

$main-1-color: #7c3777;
$main-2-color: #b76fb1;
$main-3-color: #e197db;
$main-4-color: #ffb2f8;

$minor-1-color: #5cb7f0;
$minor-2-color: #78c6fb;
$minor-3-color: #94e2ff;
$minor-4-color: #bcffff;

$other-1-color: #959083;
$other-2-color: #aca89b;
$other-3-color: #d0ccbe;
$other-4-color: #ede9da;

$grey-100: #f8f8f8;
$grey-200: #f0f0f0;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #383838;

$link: #0000ff;

$success-color: #008941;
$error-color: #cc0700;
$info: #0762c8;
$warning-color: #ffc000;
$risky-color: #ea9e45;
$transmission-color: #ff5d57;
$draft-color: #b40808;

$criticality-1-color: #d10700;
$criticality-2-color: #ffbf00ba;
$criticality-3-color: #008940de;
$criticality-4-color: #383838;

$primary-dsac-color-1: #ea5a0b;
$primary-dsac-color-2: #e28f5f;
$primary-dsac-color-3: #edbea1;
$primary-dsac-color-4: #f4ded1;

$minor-dsac-color-1: #275da8;
$minor-dsac-color-2: #6e8dbd;
$minor-dsac-color-3: #a9bedc;
$minor-dsac-color-4: #d4dfee;

$other-dsac-color-1: #706357;
$other-dsac-color-2: #c6c1bc;
$other-dsac-color-3: #c8cacf;
$other-dsac-color-4: #e2e0dd;

//RGAA color match
$orange-system-light: #ed6f00; //graphic component for white #FFFFFF ratio 3/1
$orange-system-medium: #de6800; //graphic component for grey #F0F0F0 ratio 3/1
$orange-system-hard: #bf5900; //for white #FFFFFF ratio 4.5/1

$board-background-widget: rgba(242, 231, 223, 0.849);
$board-background-button: #595959;
$board-button-hover: #6e6e6e;
